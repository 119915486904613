import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { useAppId, useApps } from "../../hook";
import { useUserIsOwner } from "../../hook/role";
import {
  useAddWorkspace,
  useAddWorkspaceUser,
  useAllUsers,
  useAllWorkspaces,
  useAppIdToApp,
  useAppsFromAllSources,
  useDeleteWorkspace,
  useDeleteWorkspaceUser,
  useFetchAllUsers,
  useFetchAllWorkspaces,
  useFetchWorkspaceUsers,
  useSetSelectedApp,
  useWorkspaceUsers,
} from "../../hook/settings";
import { NoteBlockContainer, NotePageContainer } from "../note";

export const WorkspaceTable: React.FC = () => {
  const [apps, setApps] = useApps();
  const [appId, setAppId] = useAppId();
  const setSelectedApp = useSetSelectedApp();
  const { user } = useAuth0();

  return (
    <NoteBlockContainer name="My Workspaces">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Workspace</TableCell>
            <TableCell>Selected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.map((app) => {
            const checked = app.id === appId;
            return (
              <TableRow key={app.id}>
                <TableCell>{app.name}</TableCell>
                <TableCell>
                  <Checkbox
                    onClick={() => {
                      setSelectedApp({ appId: app.id, email: user?.email! });
                    }}
                    disabled={checked}
                    checked={checked}
                  ></Checkbox>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </NoteBlockContainer>
  );
};

export interface WorkspaceUserFormData {
  email: string;
  name: string;
  "app-name"?: string;
}

export const initialWorkspaceUserFormData: WorkspaceUserFormData = {
  email: "",
  name: "",
};

export const AddWorkspaceUserModal: React.FC<{
  setFormData: (setFormData: WorkspaceUserFormData) => any;
  formData: WorkspaceUserFormData;
  modalOpen: boolean;
  setModelOpen: (isOpen: boolean) => any;
  onSubmit: (config: { formData: WorkspaceUserFormData; appId: string }) => any;
  appId: string;
  appSelector?: boolean;
  appTextInput?: boolean;
}> = ({
  setFormData,
  formData,
  modalOpen,
  setModelOpen,
  onSubmit,
  appId,
  appSelector = false,
  appTextInput = false,
}) => {
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const apps = useAppsFromAllSources();
  const appIdToApp = useAppIdToApp();
  const [selectedApp, setSelectedApp] = useState(appIdToApp(appId));
  const appName = selectedApp?.name;

  const validateForm = () => {
    const emailFormErrors = formData["email"] ? [] : ["email"];
    const nameFormErrors = formData["name"] ? [] : ["name"];
    const formErrors = [...emailFormErrors, ...nameFormErrors];
    setFormErrors(formErrors);
    return formErrors.length === 0;
  };

  const setFormField = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Modal open={modalOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          elevation={7}
          sx={{
            padding: "2rem",
            width: "40rem",
            gap: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">Add User To {appName}</Typography>
          <TextField
            value={formData["email"]}
            label="Email"
            placeholder="johndoe@gmail.com"
            error={R.includes("email", formErrors)}
            helperText={
              R.includes("email", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("email", e.target.value)}
          />
          <TextField
            value={formData["name"]}
            label="Name"
            placeholder="John Doe"
            error={R.includes("name", formErrors)}
            helperText={R.includes("name", formErrors) ? "Required" : undefined}
            onChange={(e) => setFormField("name", e.target.value)}
          />
          {appTextInput && (
            <TextField
              value={formData["app-name"]}
              label="Workspace Name"
              placeholder="My Workspace"
              error={R.includes("app-name", formErrors)}
              helperText={
                R.includes("app-name", formErrors) ? "Required" : undefined
              }
              onChange={(e) => setFormField("name", e.target.value)}
            />
          )}
          {appSelector && (
            <Select value={selectedApp?.id}>
              {apps.map((app) => {
                return (
                  <MenuItem
                    key={app.id}
                    onClick={() => {
                      setSelectedApp(app);
                    }}
                    value={app.id}
                  >
                    {app.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          <Box>
            <Button
              onClick={() => {
                if (!validateForm()) return;
                onSubmit({ formData, appId: selectedApp.id });
                setModelOpen(false);
              }}
            >
              Add
            </Button>
            <Button onClick={() => setModelOpen(false)}>Cancel</Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export const WorkspaceUserTable: React.FC = () => {
  const [appId, setAppId] = useAppId();
  const [workspaceUsers, setWorkspaceUsers] = useWorkspaceUsers();
  const fetchWorkspaceUsers = useFetchWorkspaceUsers();
  const theme = useTheme();
  const addWorkspaceUser = useAddWorkspaceUser();
  const deleteWorkspaceUser = useDeleteWorkspaceUser();
  const [addUserModalOpen, setAddUserModelOpen] = useState(false);
  const [formData, setFormData] = useState<WorkspaceUserFormData>(
    initialWorkspaceUserFormData
  );

  useEffect(() => {
    if (workspaceUsers.length !== 0) return;
    if (!appId) return;
    fetchWorkspaceUsers({ appId });
  }, [appId]);

  return (
    <>
      <AddWorkspaceUserModal
        appId={appId!}
        formData={formData}
        setFormData={setFormData}
        modalOpen={addUserModalOpen}
        setModelOpen={setAddUserModelOpen}
        onSubmit={({ formData: { email, name }, appId }) => {
          addWorkspaceUser({ email, name, appId });
        }}
      />
      <NoteBlockContainer name="Workspace Users">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workspaceUsers.map((workspaceUser) => {
              return (
                <TableRow key={workspaceUser.email}>
                  <TableCell>{workspaceUser.email}</TableCell>
                  <TableCell>{workspaceUser.name}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        deleteWorkspaceUser({
                          appId: appId!,
                          email: workspaceUser.email,
                        })
                      }
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ justifyContent: "end", display: "flex" }}>
          <IconButton
            onClick={() => setAddUserModelOpen(true)}
            sx={{ color: theme.palette.primary.main }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </NoteBlockContainer>
    </>
  );
};

export interface WorkspaceFormData {
  ownerEmail: string;
  ownerName: string;
  appName: string;
}

export const initialWorkspaceFormData: WorkspaceFormData = {
  ownerEmail: "",
  ownerName: "",
  appName: "",
};

export const AddWorkspaceModal: React.FC<{
  setFormData: (setFormData: WorkspaceFormData) => any;
  formData: WorkspaceFormData;
  modalOpen: boolean;
  setModelOpen: (isOpen: boolean) => any;
  onSubmit: (config: { formData: WorkspaceFormData }) => any;
}> = ({ setFormData, formData, modalOpen, setModelOpen, onSubmit }) => {
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const validateForm = () => {
    const emailFormErrors = formData.ownerEmail ? [] : ["ownerEmail"];
    const appNameFormErrors = formData.appName ? [] : ["appName"];
    const ownerNameFormErrors = formData.ownerName ? [] : ["ownerName"];
    const formErrors = [
      ...emailFormErrors,
      ...appNameFormErrors,
      ...ownerNameFormErrors,
    ];
    setFormErrors(formErrors);
    return formErrors.length === 0;
  };

  const setFormField = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <Modal open={modalOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          elevation={7}
          sx={{
            padding: "2rem",
            width: "40rem",
            gap: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6">Add New Workspace</Typography>
          <TextField
            value={formData.ownerEmail}
            label="Owner Email"
            placeholder="johndoe@gmail.com"
            error={R.includes("ownerEmail", formErrors)}
            helperText={
              R.includes("ownerEmail", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("ownerEmail", e.target.value)}
          />
          <TextField
            value={formData.ownerName}
            label="Owner Name"
            placeholder="John Doe"
            error={R.includes("ownerName", formErrors)}
            helperText={
              R.includes("ownerName", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("ownerName", e.target.value)}
          />
          <TextField
            value={formData.appName}
            label="Workspace Name"
            placeholder="My Workspace"
            error={R.includes("appName", formErrors)}
            helperText={
              R.includes("appName", formErrors) ? "Required" : undefined
            }
            onChange={(e) => setFormField("appName", e.target.value)}
          />
          <Box>
            <Button
              onClick={() => {
                if (!validateForm()) return;
                onSubmit({ formData });
                setModelOpen(false);
              }}
            >
              Add
            </Button>
            <Button onClick={() => setModelOpen(false)}>Cancel</Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export const AdminWorkspaceTable: React.FC = () => {
  const [allWorkspaces, setAllWorkspaces] = useAllWorkspaces();
  const fetchAllWorkspaces = useFetchAllWorkspaces();
  const theme = useTheme();
  const deleteWorkspace = useDeleteWorkspace();
  const addWorkspace = useAddWorkspace();
  // const [formData, setFormData] = useState<WorkspaceFormData>({
  //   ownerEmail: "andreasv@artios.io",
  //   ownerName: "Andreas",
  //   appName: "Artios Workspace",
  // });
  const [formData, setFormData] = useState(initialWorkspaceFormData);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (allWorkspaces.length !== 0) return;
    fetchAllWorkspaces();
  }, []);

  return (
    <>
      <AddWorkspaceModal
        formData={formData}
        setFormData={setFormData}
        modalOpen={modalOpen}
        setModelOpen={setModalOpen}
        onSubmit={({ formData: { ownerEmail, appName, ownerName } }) => {
          addWorkspace({
            ownerEmail,
            appName,
            ownerName,
          });
        }}
      />
      <NoteBlockContainer name="Workspaces">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allWorkspaces.map((workspace) => {
              return (
                <TableRow key={workspace.name}>
                  <TableCell>{workspace.name}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        deleteWorkspace({ appId: workspace.id });
                      }}
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ justifyContent: "end", display: "flex" }}>
          <IconButton
            onClick={() => setModalOpen(true)}
            sx={{ color: theme.palette.primary.main }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </NoteBlockContainer>
    </>
  );
};

export const WorkspaceSettings: React.FC = () => {
  return (
    <NotePageContainer pageName="Workspace Settings">
      <WorkspaceTable />
      <WorkspaceUserTable />
      <></>
    </NotePageContainer>
  );
};

export const AdminWorkspaceUserTable: React.FC = () => {
  const [appUsers, setAppUsers] = useAllUsers();
  const fetchAppUsers = useFetchAllUsers();
  const theme = useTheme();
  const apps = useAppsFromAllSources();
  const appIdToApp = useAppIdToApp();
  const [addUserModalOpen, setAddUserModelOpen] = useState(false);
  const [formData, setFormData] = useState<WorkspaceUserFormData>(
    initialWorkspaceUserFormData
  );
  const [appId, setAppId] = useState(apps?.[0]?.id);
  const addWorkspaceUser = useAddWorkspaceUser();
  const deleteWorkspaceUser = useDeleteWorkspaceUser();
  const fetchAllWorkspaces = useFetchAllWorkspaces();

  useEffect(() => {
    if (apps.length !== 0) return;

    fetchAllWorkspaces();
  });

  useEffect(() => {
    if (appUsers.length !== 0) return;

    fetchAppUsers();
  }, [appUsers]);

  return (
    <>
      <AddWorkspaceUserModal
        appId={appId!}
        formData={formData}
        setFormData={setFormData}
        modalOpen={addUserModalOpen}
        setModelOpen={setAddUserModelOpen}
        onSubmit={({ formData: { email, name }, appId }) => {
          addWorkspaceUser({ email, name, appId });
        }}
        appSelector={true}
      />
      <NoteBlockContainer name="All Workspace Users">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Workspace</TableCell>
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appUsers.map((appUser, i) => {
                const app = appIdToApp(appUser["app-id"]);

                return (
                  <TableRow key={i}>
                    <TableCell>{appUser.email}</TableCell>
                    <TableCell>{app.name}</TableCell>
                    <TableCell>{appUser.name}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          deleteWorkspaceUser({
                            appId: appUser["app-id"],
                            email: appUser.email,
                          })
                        }
                        sx={{ color: theme.palette.primary.main }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box sx={{ justifyContent: "end", display: "flex" }}>
            <IconButton
              onClick={() => {
                setAddUserModelOpen(true);
              }}
              sx={{ color: theme.palette.primary.main }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </TableContainer>
      </NoteBlockContainer>
    </>
  );
};

export const AdminSettings: React.FC = () => {
  return (
    <NotePageContainer pageName="Admin Settings">
      <AdminWorkspaceTable />
      <AdminWorkspaceUserTable />
      <></>
    </NotePageContainer>
  );
};

export const SettingsPage: React.FC = () => {
  const isOwner = useUserIsOwner();

  return (
    <Stack gap={3}>
      <WorkspaceSettings />
      {isOwner && <AdminSettings />}
    </Stack>
  );
};
