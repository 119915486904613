import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { GetRolesResponse } from "../api";
import { ActionType, AppDispatch, RootState, UserRole } from "../reducer";

export const useRoles: () => [UserRole[], (roles: UserRole[]) => void] = () => {
  const roles = useSelector((state: RootState) => state.main.roles);
  const dispatch = useDispatch<AppDispatch>();
  const setRoles = (roles: UserRole[]) =>
    dispatch({ type: ActionType.SET_ROLES, roles });
  return [roles, setRoles];
};

export enum Roles {
  OWNER = "Owner",
}

export const useUserIsOwner: () => boolean = () => {
  const [roles, setRoles] = useRoles();
  return R.any((role) => role.role === Roles.OWNER, roles);
};

export const useHandleGetRolesResponse: () => (
  reponse: GetRolesResponse
) => void = () => {
  const [roles, setRoles] = useRoles();
  return (response) => {
    setRoles(response.roles);
  };
};
